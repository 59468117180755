Application.Controller.Testimonial = (function($) {
    function edit() {
        verifyType();
        $("#a_testimonial_type").on("change", verifyType);

        function verifyType() {
            let selectedText = $("#a_testimonial_type").find("option:selected").text();
            if ('Vídeo' == selectedText) {
                $('#a_testimonial_text').parent().hide();        
                $('#a_testimonial_video').parent().show();        

                $('#a_testimonial_text').attr('required');
                $('#a_testimonial_video').removeAttr('required');
            } else {
                $('#a_testimonial_text').parent().show();        
                $('#a_testimonial_video').parent().hide();        

                $('#a_testimonial_text').removeAttr('required');
                $('#a_testimonial_video').attr('required');
            }
        }
    }

    return {
        'new': edit,
        'create': edit,
        'edit': edit,
        'update': edit,
    };
})(jQuery);
